.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f1ea;
    color: #333;
    padding: 10px 20px;
  }

  .header-content{
    font-family: 'Arial', sans-serif;
    display: flex;
    justify-content: space-between;
    min-width: 95%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f1ea;
    color: #333;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
  
  .header .logo a {
    color: #c59d5f;
    text-decoration: none;
    font-weight: bold;
    font-size: 24px;
  }
  
  .header .navigation a {
    color: #333;
    text-decoration: none;
    margin: 0 10px;
    font-weight: 500;
  }
  
  .header .navigation a:hover {
    color: #c59d5f;
  }

  .header .icons-container nav a {
    color: #c59d5f;
    margin: 0 5px;
  }
  
  .header .icons-container nav a:hover {
    color: #333;
  }
  
  .header .auth button {
    background-color: transparent;
    color: #c59d5f;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
  .header .auth button:hover {
    color: #333;
  }

  .header .icons-container .MuiSvgIcon-root {
    fill: #c59d5f;
  }
  
  
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .header .navigation-container,
    .header .search-container {
      width: 100%;
    }
  
    .header .navigation {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .header .navigation a {
      padding: 10px 0;
    }
  }

  
  .auth-dropdown{
    position: relative;
    cursor: pointer;
    
  }
  
  .auth-popup{
    position: absolute;
    top: 20px;
    left: -60px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    z-index: 999; /* чтобы окно было поверх других элементов */
    display: grid;
    max-width: 95px;
    grid-template-columns: repeat(1, 3fr);   
  }

  .auth-popup a{
    color: #333;
    text-decoration: none;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 4px;
  }
  
  .auth-item {
    padding: 5px 0;
    cursor: pointer;
  }
  
  .auth-item:hover {
    background-color: #978484; /* изменяем цвет при наведении */
  }