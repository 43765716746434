
.product-item-A {
  width: 120px; /* Width of the product item */
  height: auto; /* Height is set to auto to accommodate content */
  border: 1px solid #ddd;
  padding: 1px; /* Set padding to 1px for all sides */
  text-align: center;
  background-color: #fff;
  box-sizing: border-box; /* Make sure padding and border are included in the width and height */
}

.product-image-A {
  width: calc(100% - 2px); /* Width - total horizontal padding */
  height: 80px; /* Height of the image */
  background-color: #f3f3f3; /* Background color for the image */
  margin: 1px; /* Margin of 1px on all sides */
  box-sizing: border-box; /* Include margin in the size calculation */
}


.product-item-A .price-A {
  font-size: 1em;
  color: #333;
  margin-bottom: 1px; /* Margin at the bottom to separate from container's edge */
}
