.pagination {
    display: flex;
    list-style: none;
    padding: 0;
  }
  
  .page-item {
    margin-right: 5px;
  }
  
  .page-item.active .page-link {
    background-color: #ff4500;
    color: white;
  }
  
  .page-link {
    border: 1px solid #ddd;
    padding: 5px 10px;
    text-decoration: none;
    cursor: pointer;
    background-color: #fff;
    color: #333;
  }
  
  .page-link:hover {
    background-color: #f4f4f4;
  }
  