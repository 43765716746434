/* src/components/TopBanner.css */

.top-banner {
    background-color: #9a3434; /* Цвет фона */
    text-align: center;
    padding: 20px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px; /* Отступ, чтобы отделить от следующего контента */
  }
  
  .top-banner h2 {
    margin: 0;
    color: #333; /* Цвет текста */
    font-size: 1.5em; /* Размер шрифта */
  }
  
  /* Адаптивность для мобильных устройств */
  @media (max-width: 768px) {
    .top-banner h2 {
      font-size: 1.2em; /* Меньший размер шрифта для устройств с меньшим экраном */
      padding: 0 10px; /* Добавляем padding, чтобы текст не прилипал к краям */
    }
  }
  
  @media (max-width: 480px) {
    .top-banner h2 {
      font-size: 1em; /* Еще меньше для мобильных телефонов */
    }
  }
  