/* src/components/Main.css */
.test {
  position: relative;
}
.main {
    padding-top: 60px; /* Должно быть равно или больше высоты Header */
    padding-bottom: 40px; /* Должно быть равно или больше высоты Footer */
    text-align: center;
  }
  
  @media (max-width: 768px) {
    /* Стили для планшетов */
    .main {
      padding: 60px 10px; /* Уменьшаем боковые отступы */
    }
  
    /* Здесь могут быть другие стили, специфичные для планшетов */
  }
  
  @media (max-width: 480px) {
    /* Стили для смартфонов */
    .main {
      padding: 50px 5px; /* Ещё меньше боковых отступов для смартфонов */
    }
  
    /* Можете добавить стили, чтобы уменьшить размеры или изменить расположение блоков */
    /* Пример: */
    .main-content {
      flex-direction: column;
      max-width: 1200px;
    }
  
    .some-main-section {
      width: 100%; /* Занимает всю доступную ширину */
      margin-bottom: 15px; /* Добавляет пространство между секциями */
    }
  }  