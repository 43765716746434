.order-details-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    max-width: 700px;
    margin: 30px auto;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .breadcrumbs {
    margin-bottom: 20px;
    font-size: 0.9em;
    color: #6c757d;
    text-align: left;
  }
  
  .order-details-content h1 {
    text-align: left;
    margin-bottom: 25px;
    font-size: 1.5em;
  }
  
  .product-list1 {
    display: flex;
    flex-direction: column;
  }
  
  .product-card {
    display: flex;
    align-items: flex-start;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
  }
  
  .product-image1 {
    flex-shrink: 0;
    width: 70px;
    height: 70px;
    margin-right: 15px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .product-image1 img {
    width: 100%;
    height: 100%;
  }
  
  .product-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  
  .product-header {
    display: flex;
    justify-content: space-between;
    font-size: 0.9em;
    color: #6c757d;
  }
  
  .product-details {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  
  .product-name-label,
  .product-quantity-label,
  .product-price-label,
  .product-name,
  .product-quantity,
  .product-price {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
  
  .product-name {
    font-weight: bold;
    font-size: 1.1em;
    margin-top: 5px;
  }
  
  .product-quantity,
  .product-price {
    background-color: #f9f9f9;
    padding: 5px;
    border-radius: 4px;
    margin-top: 5px;
  }
  
  .total-price {
    font-weight: bold;
    text-align: right;
    margin-top: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    color: #28a745;
    border-radius: 4px;
}

/* Дополнительные стили для адаптации макета под мобильные устройства и небольшие экраны */
@media (max-width: 768px) {
  .product-header, .product-details {
    flex-direction: column;
    align-items: flex-start;
  }

  .product-name-label,
  .product-quantity-label,
  .product-price-label,
  .product-name,
  .product-quantity,
  .product-price {
    text-align: left;
    margin-top: 5px;
  }

  .product-image1 {
    margin-bottom: 10px; /* Дополнительное пространство для мобильных устройств */
  }
}

/* Дополнительные стили для кнопок и других интерактивных элементов, если они есть на странице */
/* button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
} */
