.home-container {
    padding: 20px;
  }
  
  .article-container {
    margin-top: 20px;
  }
  
  .article {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .article h2 {
    margin-top: 0;
  }
  
  .article a {
    display: inline-block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
  }
  
  .article a:hover {
    text-decoration: underline;
  }
  
  .slick-slider img {
    max-height: 200px; /* Уменьшает высоту изображений до 300px */
    width: 100%; /* Сохраняет пропорции изображения */
  }
  
  