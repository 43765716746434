.articles-container {
display: grid;
grid-template-columns: repeat(3, 1fr); /* Создаем три колонки с равной шириной */
gap: 20px; /* Отступы между карточками */
margin-bottom: 20px; /* Отступ снизу для всего контейнера */
}

.article-card {
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
overflow: hidden;
border-radius: 8px;
}

@media (max-width: 1024px) {
.articles-container {
grid-template-columns: repeat(2, 1fr); /* Две колонки на уменьшенных экранах */
}
}

@media (max-width: 768px) {
.articles-container {
grid-template-columns: 1fr; /* Одна колонка на мобильных устройствах */
}
}
