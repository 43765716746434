/* src/components/ProductItem.css */

.product-item {
    border: 1px solid #ddd;
    padding: 16px;
    text-align: center;
    background-color: #fff;
  }
  
  .product-image {
    width: 100%; /* Ширина изображения */
    height: 200px; /* Высота изображения */
    background-color: #f3f3f3; /* Цвет фона для изображения */
    margin-bottom: 8px;
  }
  
  .product-item h3 {
    margin: 8px 0;
    font-size: 1em;
  }
  
  .product-item .price {
    font-size: 1em;
    color: #333;
  }
  
  .product-item button {
    background-color: #5cb85c;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 8px;
    cursor: pointer;
  }
  
  .product-item button:hover {
    background-color: #4cae4c;
  }
  