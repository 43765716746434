.favourites-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex; /* Установка flex-контейнера */
  flex-direction: column; /* Элементы выстраиваются вертикально */
}

.breadcrumbs {
  margin-bottom: 10px;
  color: #888;
  align-self: flex-start; /* Выравнивание по левому краю */
}

.favourites-title {
  font-size: 24px;
  color: #333;
  margin: 0 0 20px; /* Убран нижний отступ и добавлен отступ снизу */
  align-self: flex-start; /* Выравнивание по левому краю */
}

.price-info-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.favourites-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.favourites-title {
  font-size: 24px;
  color: #333;
  margin: 0 0 20px; /* Задайте желаемый отступ снизу */
  align-self: flex-start; 
}

.price-info-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.price-info {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #e9ecef; 
  border-radius: 4px;
}

.clear-filters {
  background: none;
  border: 1px solid #e9ecef;
  color: #333;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.favourites-content {
  display: flex;
  width: 100%; /* Для заполнения всей доступной ширины */
}

.favourites-filters {
  width: 240px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 20px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.filter-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  padding: 10px;
  background-color: #f3f3f3;
  border-radius: 4px;
}

.price-filter {
  margin-bottom: 20px;
}

.price-filter-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.price-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.price-input {
  width: calc(50% - 4px);
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.clear-filter {
  background: none;
  border: none;
  color: #008000;
  cursor: pointer;
  font-size: 14px;
}

.price-range {
  width: 100%;
  margin-bottom: 20px;
}

.categories-filter {
  margin-bottom: 20px;
}

.category-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.category-option input {
  margin-right: 10px;
}

.availability-filter {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.availability-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.availability-switch input:checked + .slider {
  background-color: #2196F3;
}

.availability-switch input:checked + .slider:before {
  transform: translateX(26px);
}

.apply-filters {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #ff4500;
  color: white;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.favourites-right {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.favourites-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 20px; /* Add a top margin if needed */
}

.show-more {
  padding: 10px 20px;
  border: none;
  background-color: #ff4500;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: auto;
}

.favourites-pagination {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (max-width: 1200px) {
  .favourites-items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 800px) {
  .favourites-items {
    grid-template-columns: 1fr;
  }

  .favourites-filters, .favourites-right {
    width: auto;
    margin-right: 0;
  }
}
