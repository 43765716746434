.orders-page {
  font-family: 'Arial', sans-serif;
  color: #333;
}

.breadcrumbs {
  margin: 10px 0;
  font-size: 0.9em;
  color: #555;
  text-align: left;
}

.orders-content h1 {
  text-align: left;
}

.order-list {
  display: flex;
  flex-direction: column;
}

.order-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.order-details {
  display: flex;
  align-items: center;
}

.order-date,
.order-time,
.order-status {
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 4px;
  margin-right: 10px;
}

.order-status:hover,
.order-view:hover {
  background-color: #e6e6e6;
}

.order-actions {
  display: flex;
  align-items: center;
}

.order-price {
  margin-right: 10px;
  font-weight: bold;
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 4px;
}

.order-delivery {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 150px;
}

.order-delivery:hover {
  background-color: #218838;
}

.show-more {
  width: 100%;
  padding: 10px 0;
  background-color: #ddd;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s;
}

.show-more:hover {
  background-color: #ccc;
}

/* Стили для статусов заказов */
.status-in-progress {
  background: #ffc107;
}

.status-not-delivered,
.status-returned {
  background: red;
}

.status-received {
  background: #28a745;
}

/* Стили для кнопки "Заказать" */
.delivery-order {
  background: orange;
}
