.cart-cart-container {
display: flex;
max-width: 1200px;
margin: 20px auto;
flex-wrap: wrap;
}

.cart-breadcrumbs a, .cart-breadcrumbs span {
color: #666;
margin-right: 5px;
}

.cart-breadcrumbs a {
text-decoration: none;
}

.cart-cart-title {
flex-basis: 100%;
margin: 20px 0;
text-align: left;
}

.cart-cart-upper-actions {
display: flex;
align-items: center;
justify-content: space-between;
flex-basis: 100%;
margin-bottom: 20px;
}

.cart-select-all-checkbox {
margin-right: 10px;
}

.cart-select-all-label {
margin-right: 10px;

}

.cart-delete-selected {
background-color: green;
color: white;
border: none;
padding: 10px 20px;
cursor: pointer;
}

.cart-cart-items-container {
display: flex;
flex-direction: column;
flex-grow: 1; /* Блоки товаров будут занимать все доступное пространство по ширине */
margin-right: 20px;
}

.cart-cart-item {
display: flex;
align-items: center;
border: 1px solid #ddd;
padding: 10px;
margin-bottom: 10px;
}

.cart-cart-item-select {
margin-right: 10px;
}

.cart-cart-item-image {
width: 60px;
height: 60px;
background-color: #ddd;
margin-right: 10px;
}

.cart-cart-item-info {
display: flex;
flex-direction: column;
margin-right: auto;
}

.cart-cart-item-title, .cart-cart-item-price {
line-height: 1.5;
}

.cart-cart-item-quantity-container {
display: flex;
align-items: center;
}

.cart-quantity-button {
background: none;
border: 1px solid #ccc;
padding: 5px;
margin: 0 5px;
}

.cart-quantity-number {
margin: 0 10px;
}

.cart-item-total-price {
margin-left: 10px;
font-weight: bold;
}

.cart-cart-summary {
flex-basis: 200px;
border: 1px solid #ddd;
padding: 10px;
align-self: flex-start;
}

.cart-summary-item, .cart-summary-total {
display: flex;
justify-content: space-between;
margin-bottom: 10px;
}

.cart-checkout-button {
width: 100%;
background-color: orange;
color: white;
border: none;
padding: 10px;
cursor: pointer;
}

.cart-minimum-amount {
text-align: center;
color: #888;
font-size: 0.9em;
margin-top: 10px;
}
