/* Общие стили для контейнера */
.delivery-container {
font-family: 'Arial', sans-serif;
min-width: 90%;
margin: 0 auto;
padding: 20px;
background: #fff;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Стили для "хлебных крошек" и заголовка */
.breadcrumbs {
font-size: 14px;
color: #757575;
margin-bottom: 16px;
}

.breadcrumbs a {
color: inherit;
text-decoration: none;
}

.breadcrumbs span::after {
margin: 0 5px;
}

h1 {
font-size: 24px;
color: #333;
margin-bottom: 20px;
}

/* Стили для блоков формы */
.delivery-form {
display: flex;
gap: 20px;
}

.deliveryF-block-2 {
width: 70%;
padding: 20px;
background: #f9f9f9;
border-radius: 4px;
}

.deliveryF-block-3 {
width: 30%;
padding: 20px;
background: #f9f9f9;
border-radius: 4px;
}

.address-block,
.time-block,
.login-block {
margin-bottom: 20px;
text-align: left;
}

.address-field,
.login-block-1 {
display: flex;
flex-direction: column;
}

.address-field label,
.del-date,
.del-time,
.login-block label {
font-size: 14px;
margin-bottom: 5px;

}


.select,
.login-button,
.time-slot, .Home1234, .Home123 {
padding: 10px;
margin-bottom: 10px;
border: 1px solid #ccc;
border-radius: 4px;
}

.adres-block-1 {
display: flex;
gap: 10px;
}

.time-block-1 {
display: flex;
flex-direction: column;
}

.time-slots {
display: flex;
justify-content: start;
flex-wrap: wrap;
gap: 10px;
}

.time-slot {
background-color: #e9e9e9;
border: 1px solid #ccc;
padding: 10px;
border-radius: 4px;
cursor: pointer;
}

.time-slot.active {
background-color: #4CAF50;
color: white;
border-color: #4CAF50;
}

.summary-block {
font-size: 14px;
margin-bottom: 16px;
}

.payment-buttons button {
width: 100%;
padding: 10px;
border: none;
border-radius: 4px;
margin-bottom: 10px;
cursor: pointer;
font-weight: bold;
}

.pay-site {
background-color: #4CAF50;
color: white;
}

.pay-delivery {
background-color: #f44336;
color: white;
}
.time-block-1{
flex-direction: row;
}
.del-time{
display: flex;
flex-direction: row;

}
.login-block-1{
flex-direction: row;
display: flex;
}
.time-slots-123{
flex-direction: column;
gap: 20px;
padding-left: 20px;
}
.Home123{
width: 30px;
}
.oplata-1{
display: flex;
justify-content: space-between;
margin-bottom: 10px;

}

.home12345{
padding: 10px;
margin-bottom: 10px;
border: 1px solid #ccc;
border-radius: 4px;
}
@media (max-width: 768px) {
.delivery-form {
flex-direction: column;
}

.deliveryF-block-2,
.deliveryF-block-3 {
width: 100%;
margin-bottom: 20px;
}

.adres-block-1 {
flex-direction: column;
}

.time-slots {
flex-direction: column;
}

.time-slot {
width: 100%;
margin-right: 0;
}
}
