.SearchingResults-conteiner{
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex; /* Установка flex-контейнера */
    flex-direction: column; /* Элементы выстраиваются вертикально */
}
.SearchingResults-content{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin-top: 20px; /* Add a top margin if needed */
}
.SRZagalovok{
    flex-basis: 100%;
    margin: 20px 0;
    text-align: left;
}
.SRtext{
    flex-basis: 100%;
    margin: 20px 0;
    text-align: left;
    font-size: 18px;
}
.SREda{
    color: #e97d11;
}


