.product-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 столбца с равной шириной */
  gap: 16px; /* Промежуток между элементами */
  margin: 0 auto; /* Центрируем список товаров */
  max-width: 1200px; /* Максимальная ширина списка товаров */
  padding: 0 16px; /* Отступы справа и слева */
}

.product-item {
  text-align: center;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px; /* Для отступа от элементов ниже */
}

.product-image {
  height: 150px; /* Фиксированная высота для образца */
  background-color: #f3f3f3; /* Цвет фона для визуализации области изображения */
  margin-bottom: 10px;
}

.price {
  color: #333;
  font-weight: bold;
  margin: 10px 0;
}

/* Адаптивность */
@media (max-width: 768px) {
  .product-list {
      grid-template-columns: repeat(2, 1fr); /* 2 столбца на маленьких экранах */
  }

  .product-item {
      margin-bottom: 20px;
  }
}
