/* src/components/SpecialOffers.css */

.special-offers-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 20px; /* Отступ между карточками */
    max-width: 1200px; /* Максимальная ширина списка товаров */
    margin: 0 auto; /* Центрируем список товаров */
  }
  
  .special-offer-card {
    flex: 1; /* Каждая карточка занимает равное пространство */
    border: 2px solid #ddd; /* Граница карточки */
    padding: 20px;
    border-radius: 8px; /* Скругление углов карточки */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Тень для карточки */
  }
  
  .loyalty-card {
    background-color: #ffece2; /* Пример цвета фона для карточки лояльности */
  }
  
  .discount-card {
    background-color: #e2ffef; /* Пример цвета фона для карточки скидок */
  }
  
  /* Адаптивность */
  @media (max-width: 768px) {
    .special-offers-container {
      flex-direction: column;
    }
  }
  