/* Стили для основного контейнера */
.cardp-card-product {
font-family: 'Arial', sans-serif;
max-width: 1200px;
margin: 0 auto;
padding: 20px;
display: flex; /* Установка flex-контейнера */
flex-direction: column; 
}

/* Стили для хлебных крошек */
.cardp-breadcrumb-container .cardp-breadcrumbs {
font-size: 14px;
color: #333;
margin-bottom: 10px; /* Отступ между хлебными крошками и заголовком товара */
text-align: left;
}

.cardp-breadcrumbs a {
text-decoration: none; /* Убирает подчёркивание у ссылок */
color: #333; /* Цвет текста ссылок */
}

.cardp-breadcrumbs a:hover {
text-decoration: underline; /* Добавляет подчёркивание при наведении */
}

/* Стили для заголовка товара */
.cardp-product-title {
font-size: 24px; /* Размер шрифта заголовка товара */
color: #333; /* Цвет текста заголовка товара */
margin-bottom: 20px; /* Отступ снизу заголовка товара */
text-align: left;
}

.cardp-product-header {
display: flex;
align-items: center;
padding: 10px 20px;
}

.cardp-product-art {
font-size: 14px;
color: #333;
margin-right: 16px; /* Регулируйте это значение, чтобы установить расстояние между артикулом и кнопкой */
}

.cardp-favorite-button {
padding: 8px 16px;
font-size: 14px;
background-color: #f2f2f2; /* Светло-серый фон */
color: #333; /* Тёмный текст для контраста */
border: 1px solid #dcdcdc; /* Граница немного темнее фона */
cursor: pointer;
border-radius: 4px; /* Скруглённые углы */
outline: none; /* Убрать обводку при фокусе */
transition: background-color 0.3s ease; /* Плавное изменение фона при наведении */
}

.cardp-favorite-button:hover {
background-color: #eaeaea; /* Чуть темнее фон при наведении */
}

/* Стили для галереи изображений */
.cardp-product-gallery {
display: flex;
padding: 20px;
}

.cardp-thumbnails {
display: flex;
flex-direction: column;
gap: 10px; /* Расстояние между миниатюрами */
margin-right: 20px; /* Отступ между миниатюрами и большим изображением */
}

.cardp-thumbnail {
width: 64px; /* Укажите размер миниатюр */
height: 64px; /* Укажите размер миниатюр */
background-color: #504a4a; /* Цвет фона для плейсхолдера */
}

.cardp-main-image {
width: 400px; /* Укажите размер большого изображения */
height: 350px; /* Укажите размер большого изображения */
background-color: #504a4a; /* Цвет фона для плейсхолдера */
}
.cardp-card-product-details {
display: flex;
flex-direction: column;
padding: 20px;
background: #fff;
box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
}

.cardp-product-pricing {
margin-bottom: 20px; /* Добавляет пространство между блоками цен и свойств */
}

.cardp-original-price {
text-decoration: line-through;
color: #999;
margin-bottom: 5px; /* Расстояние до текущей цены */
}

.cardp-current-price {
font-size: 32px;
color: #e53935; /* Цвет основной цены */
margin-bottom: 20px; /* Расстояние до кнопки добавления в корзину */
}

.cardp-add-to-cart-btn {
background-color: #ff5722; /* Цвет фона кнопки */
color: white;
border: none;
padding: 15px 180px;
text-transform: uppercase;
cursor: pointer;
}

.cardp-product-properties {
border-top: 1px solid #eaeaea; /* Легкая линия сверху для разделения */
padding-top: 20px;
}

.cardp-property-name {
font-weight: bold;
}

.cardp-property-value {
float: right; /* Выравнивание значения свойства справа */
text-align: right;
width: 100%;
}

/* Добавляет нижние отступы к каждой строке свойств */
.cardp-brand-row,
.cardp-country-row,
.cardp-package-row {
margin-bottom: 10px;
display: flex;
}
.cardp-product-main{
display: flex;
}
.cardp-tovar-name{
display: flex;
}
.cardp-aside{
padding: 10px;
margin-left: 20px;
}