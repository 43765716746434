.article-card {
width: 90%; /* или задайте фиксированную ширину, если необходимо */
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
margin-bottom: 20px;
overflow: hidden;
border-radius: 8px;
}

.article-image {
width: 100%;
height: 200px; /* Вы можете настроить высоту под ваши нужды */
background-color: #5b5959; /* Замените цветом заглушки или изображением */
}

.article-content {
padding: 16px;
}

.article-date {
font-size: 0.9em;
color: #666;
margin-bottom: 8px;
}

.article-title {
margin: 0;
font-size: 1.2em;
color: #333;
}

.article-summary {
font-size: 1em;
color: #333;
margin: 8px 0;
}

.button-more {
background-color: #5cb85c;
color: white;
border: none;
padding: 10px 20px;
cursor: pointer;
}

.button-more:hover {
background-color: #4cae4c;
}
